// Theming and Ionic Palette Overrides
// http://ionicframework.com/docs/theming/

/** CSS Variables **/
:root {

  --ion-background-color: #fff;
  --ion-color-primary: #0E5FB8;
  --ion-color-primary-contrast: #fff;
  --ion-color-danger-contrast: #fff;
  --ion-item-border-color: #c8c7cc8e;

  --bs-header-color: #000;
  --bs-translucent: #ffffff93;
  --bs-semi-translucent: #ffffffa9;
  --bs-background: linear-gradient(
    19deg,
    rgb(255 255 255),
    rgb(226 230 253),
    rgb(253 221 254)
  );
  --bs-background-color: rgb(0, 78, 134);
}

/**
  * Fonts
  * -------------------------------------------
  */
@font-face {
  font-family: 'Now Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Now-Bold.otf');
  font-display: swap;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  :root {
    --ion-background-color: #2c2c2c;
    --ion-color-primary: #0E5FB8;
    --ion-item-border-color: #383a3e8e;

    --bs-header-color: #fff;
    --bs-translucent: #1e1e1e93;
    --bs-semi-translucent: #1e1e1eb6;
    --bs-background: linear-gradient(19deg, #0f2999 0%, #470b63 100%);
  }
}
