/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
 
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */
@import "@ionic/angular/css/palettes/dark.system.css";

ion-card {
  border-radius: 20px;
}

ion-card-title {
  font-size: 20px;
}

h3 {
  font-weight: 700 !important;
  font-size: 20px;
}

h1,
h2,
h3,
ion-card-title,
ion-list-header {
  font-family: 'Now Bold', Roboto, sans-serif;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 500;
}

ion-content {
  ion-title {
    padding-inline-start: 0px;
    font-size: 26px;
    font-family: 'Now Bold', Roboto, sans-serif;
  }
}

p,
li,
i {
  color: var(--ion-text-color);
}

a {
  text-decoration: none;
  font-weight: 500;
}

strong {
  color: var(--ion-color-dark);
}

ion-list {
  background: none !important;
}

ion-item {
  --background: none !important;
}

.figure {
  font-size: 18px;
  font-weight: 700;
}

/* Custom classes for component overrides */
.transparent-modal::part(content) {
  --background: transparent;
}

.auto-height-modal {
  --height: auto;
}

.auto-height-modal::part(content) {
  --height: auto;
}

.transparent {
  --background: transparent;

  ion-toolbar {
    --background: none;
    --border-width: 0px !important;
  }
}

.account-layout {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgb(0 0 0 / 30%), rgb(0 0 0 / 60%)),
    url('assets/account/signup_background.jpg') no-repeat center
    center / cover;

  @media screen and (max-width: 576px) {
    align-items: flex-end;
  }

  ion-card {
    width: 400px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  ion-card-title {
    font-size: 20px;
  }
}

.apple-button {
  --background: #000;
  color: #fff;
}

.icon-back-button {
  --color: var(--ion-color-dark);
  --background: var(--bs-translucent) !important;
  --border-radius: 22px;
  --icon-margin-start: -5px;
  --icon-font-size: 28px;

  width: 40px;
  height: 40px;
  margin: 8px;

  &::part(native) {
    backdrop-filter: blur(10px) !important;
    box-shadow: 0 4px 16px rgb(0 0 0 / 12%);
  }
}

/* Custom classes for translucency */
.translucent {
  background: var(--bs-translucent) !important;
  backdrop-filter: blur(20px) !important;

  --background: var(--bs-translucent) !important;

  ion-toolbar {
    --background: none;
    --border-width: 0px !important;
  }

  &::part(native) {
    background: none !important;
  }
}

.semi-translucent {
  background: var(--bs-semi-translucent) !important;

  --background: var(--bs-semi-translucent) !important;

  backdrop-filter: blur(30px) !important;

  ion-toolbar {
    --background: none;
    --border-width: 0px !important;
  }

  &::part(native) {
    background: none !important;
  }
}

ion-accordion {
  background: none;
}

.blackout-loading {
  --backdrop-opacity: 1;
}

.text-accordion {
  .ion-accordion-toggle-icon {
    vertical-align: middle;
    margin-right: 3px;
  }
}

/* Custom classes for slides */
.responsive-slides-small {
  width: 100%;

  swiper-slide {
    max-width: 70px;
    height: 60px;
  }
}

.responsive-slides-large {
  width: 100%;

  swiper-slide {
    max-width: 450px;
    height: 200px;
  }
}

.responsive-swipe-cards {
  swiper-slide {
    width: 85%;
    max-width: 400px;
  }
}

.responsive-slides {
  width: 100%;

  swiper-slide {
    max-width: 135px;
    height: 145px;
  }
}

.responsive-swipe-cards,
.responsive-slides {
  swiper-slide:first-child {
    margin-left: 10px;
  }

  swiper-slide:last-child {
    margin-right: 10px;
  }
}

#document-content {
  h3 {
    margin-top: 30px;
  }
}

:root {
  --swiper-scrollbar-drag-bg-color: rgba(255, 255, 255, 0.75);
}
